import React, { useState } from "react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import ArtworkBid from "./ArtworkBid";
import ArtworkInterest from "./ArtworkInterest";
import ArtworkSelling from "./ArtworkSelling";
import { IProps as IAppProps } from "../App";

export interface IProps {
  mode: IAppProps["mode"];
  id: number;
  artist?: string;
  title?: string;
  mockup?: string | null;
  thumb?: string | null;
  lot?: number;
  description?: string;
  category?: string;
  technique?: string;
  artistDescription?: string;
  width?: number;
  height?: number;
  depth?: number;
  price?: number;
  year?: number;
  bid?: string;
  available?: number;
  onClose: () => void;
}
export interface IState {}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  wrapper: {
    position: "relative",
  },
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    position: "absolute",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  img: {
    maxWidth: "100%",
  },
  pendingMockup: {
    height: "150px",
    textAlign: "center",
    color: theme.palette.get("primary"),
    padding: "20px 0px",
    boxSizing: "border-box",
    fontSize: "4rem",
  },
  subHeading: {
    color: theme.palette.get("primary"),
    margin: "0px 0px 8px 0px",
  },
  data: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
  price: {
    fontWeight: 400,
  },
  bidContainer: { textAlign: "center" },
  info: {
    display: "block",
    textAlign: "right",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
  },
  badge: { color: "#fff", padding: "0px 8px", borderRadius: "4px" },
  primary: {
    backgroundColor: theme.palette.get("primary"),
  },
  danger: {
    backgroundColor: theme.palette.get("danger"),
  },
  success: {
    backgroundColor: theme.palette.get("success"),
  },
});

export default function ArtworkDetails(props: IProps) {
  const classes = buildClasses(style, { ...props });
  const [bidding, setBidding] = useState(false);
  const [interest, setInterest] = useState(false);
  const [selling, setSelling] = useState(false);

  const price =
    typeof props.price === "string" ? parseInt(props.price) : props.price;
  const bid = typeof props.bid === "string" ? parseInt(props.bid) : props.bid;
  return (
    <Block>
      <div className={classes.wrapper}>
        <Grid
          spacing={16}
          vertical
          root={{ top: 0, left: 0, right: 0, bottom: 0 }}
          alignItems="flex-end"
        >
          {props.mockup !== null && (
            <Grid item lg={2}>
              {typeof props.mockup === "undefined" && (
                <div className={classes.pendingMockup}>
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                </div>
              )}
              {Boolean(props.mockup) && (
                <img className={classes.img} alt="mockup" src={props.mockup} />
              )}
            </Grid>
          )}
          <Grid item lg={"auto"}>
            {props.thumb !== null && (
              <img alt="thumb" className={classes.img} src={props.thumb} />
            )}
          </Grid>
          <Grid item lg="rest-fixed">
            <div>
              <Grid root={{ bottom: 0 }}>
                <Grid item xs="auto">
                  <span className={classes.lot}>{props.lot || props.id}</span>
                </Grid>
                <Grid item xs="rest-fixed">
                  <Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.artist])}
                        >
                          {props.artist}
                        </span>
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.lineHolder}>
                        <span
                          className={classNames([classes.line, classes.title])}
                        >
                          {props.title}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid spacing={16} vertical root={{ bottom: 0, left: 0, right: 0 }}>
              <Grid item lg={10 / 3}>
                <span className={classes.data}>
                  {props.width}x{props.height}x{props.depth}cm
                </span>
              </Grid>
              <Grid item xs={5} lg={10 / 3}>
                <span className={classes.data}>{props.category}</span>
              </Grid>
              <Grid item xs={5} lg={10 / 3}>
                <span className={classes.data}>{props.technique}</span>
              </Grid>
              {props.mode === "SELLING" && props.bid !== "0.00" && (
                <Grid item xs={10}>
                  <span
                    className={classNames([classes.badge, classes.primary])}
                  >
                    Aktuelles Gebot: {(bid || 0)?.toLocaleString()}€
                  </span>
                </Grid>
              )}
              {props.mode === "SHOWING" && props.available === 0 && (
                <Grid item xs={10}>
                  <span className={classNames([classes.badge, classes.danger])}>
                    Verkauft um: {(bid || 0)?.toLocaleString()}€
                  </span>
                </Grid>
              )}
              {props.mode === "SHOWING" && (props.available || 0) > 0 && (
                <Grid item xs={5} lg={5}>
                  <span
                    className={classNames([classes.badge, classes.success])}
                  >
                    Preis: {((price || 0) * 0.75).toLocaleString()}€
                  </span>
                </Grid>
              )}
              {props.mode === "SELLING" && (
                <Grid item xs={5} lg={5}>
                  <span className={classes.price}>
                    Rufpreis: {((price || 0) / 2)?.toLocaleString()}€
                  </span>
                </Grid>
              )}
              {props.mode === "SELLING" && (
                <Grid item xs={5} lg={5}>
                  <span className={classes.price}>
                    Listenpreis: {price?.toLocaleString()}€
                  </span>
                </Grid>
              )}
              {!bidding && !interest && !selling && (
                <Grid>
                  <h6 className={classes.subHeading}>ÜBER DEN KÜNSTLER/IN</h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.description || "",
                    }}
                  />
                </Grid>
              )}
              {props.mode === "SHOWING" && props.available === 0 && !bidding && (
                <Grid>
                  <span className={classes.info}>
                    Interesse an ähnlichen Werken.
                  </span>
                </Grid>
              )}
              {bidding && (
                <ArtworkBid
                  lot={props.lot || 0}
                  onClose={() => {
                    setBidding(false);
                  }}
                />
              )}
              {interest && (
                <ArtworkInterest
                  lot={props.lot || 0}
                  onClose={() => {
                    setInterest(false);
                  }}
                />
              )}
              {selling && (
                <ArtworkSelling
                  lot={props.lot || 0}
                  onClose={() => {
                    setSelling(false);
                  }}
                />
              )}
            </Grid>
            {!bidding && !interest && !selling && (
              <Form onSubmit={() => {}}>
                <Grid
                  spacing={16}
                  vertical
                  root={{ left: 0, right: 0, bottom: 0 }}
                  justify="flex-end"
                >
                  <Button item xs="120px" onClick={props.onClose}>
                    Schließen
                  </Button>
                  {props.mode === "SHOWING" && (props.available || 0) > 0 && (
                    <Button
                      item
                      xs="120px"
                      success
                      onClick={(e) => {
                        e.preventDefault();
                        setSelling(true);
                      }}
                    >
                      Kaufen
                    </Button>
                  )}
                  {props.mode === "SHOWING" && props.available === 0 && (
                    <Button
                      item
                      xs="120px"
                      primary
                      onClick={(e) => {
                        e.preventDefault();
                        setInterest(true);
                      }}
                    >
                      Interesse
                    </Button>
                  )}
                  {props.mode === "SELLING" && (
                    <Button
                      item
                      xs="120px"
                      success
                      onClick={(e) => {
                        e.preventDefault();
                        setBidding(true);
                      }}
                    >
                      Bieten
                    </Button>
                  )}
                </Grid>
              </Form>
            )}
          </Grid>
        </Grid>
      </div>
    </Block>
  );
}
