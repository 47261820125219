import React, { useState } from "react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Form, Text } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";
import Checkbox from "@nodeme/forms-react/lib/Components/Checkbox";
import { Grid } from "@nodeme/grid-react";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  lot: number;
  onClose: () => void;
}
export interface IState {
  name?: string;
  email?: string;
  bid?: number;
  agb: boolean;
  dsgvo: boolean;
}
export interface IErrors {
  name?: string;
  email?: string;
  bid?: string;
  agb?: string;
  dsgvo?: string;
}
const style = (theme: ITheme) => (definition: IDefinitions) => ({
  wrapper: { position: "relative" },
  pending: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "100%",
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "20px",
  },
  success: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "calc(100% - 58px)",
    color: theme.palette.get("success"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "20px",
  },
  error: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 5,
    width: "100%",
    height: "calc(100% - 48px)",
    color: theme.palette.get("danger"),
    textAlign: "center",
    fontSize: "4rem",
    boxSizing: "border-box",
    paddingTop: "10px",
  },
  errorText: {
    fontSize: "1rem",
    margin: 0,
  },
  agbsError: {
    margin: "0px 0px 0px 0px",
    color: theme.palette.get("danger"),
  },
  checkboxText: {
    margin: "8px 0px 16px 0px",
  },
  info: {
    fontSize: "0.8rem",
    color: " #000",
  },
});

const sendContact = async (
  lot: number,
  values: IState,
  setErrors: (errors: IErrors) => void,
  setState: (state: string) => void
) => {
  try {
    setState("pending");
    const result = await Axios.post(
      "https://artware2.datenbanker.at/api/cart/add",
      {
        name: values.name,
        phone: "",
        email: values.email,
        description: `KAUFEN lot: ${lot}`,
        agbs: true,
        cart: JSON.stringify([]),
      }
    );
    setState("ok");
    if (result.data.error) setErrors(result.data.errors);
    else setState("success");
  } catch (error) {
    setState("error");
  }
};

export default function ArtworkInterest(props: IProps) {
  const classes = buildClasses(style, { ...props });
  const [state, setState] = useState("ok");
  const [values, setValues] = useState<IState>({
    name: "",
    email: "",
    agb: false,
    dsgvo: false,
  });
  const [errors, setErrors] = useState<IErrors>({});

  return (
    <div className={classes.wrapper}>
      {state === "pending" && (
        <div className={classes.pending}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      )}
      {state === "success" && (
        <div className={classes.success}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
      {state === "error" && (
        <div className={classes.error}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <p className={classes.errorText}>
            Ein unbekannter Fehler ist aufgetreten.
          </p>
        </div>
      )}
      <Form
        spacing={8}
        root={{ top: 0, left: 0, right: 0, bottom: 0 }}
        onSubmit={() => {}}
      >
        <Text
          item
          label="Name"
          value={values.name}
          error={errors.name}
          onChange={(value) => setValues({ ...values, name: value })}
          inline
        />
        <Text
          item
          label="Email"
          value={values.email}
          error={errors.email}
          onChange={(value) => setValues({ ...values, email: value })}
          inline
        />
        <Grid spacing={0} vertical root={{ left: 0, right: 0, top: 0 }}>
          <Checkbox
            item
            xs="auto"
            label=""
            value={values.dsgvo}
            onChange={(value) => {
              setValues({ ...values, dsgvo: value });
            }}
          />
          <Grid item xs="rest-fixed">
            <div className={classes.checkboxText}>
              Ich akzeptiere die{" "}
              <a target="__blank" href="/docs/Datenschutzerklärung.pdf">
                Datenschutzerklärung
              </a>
              .
            </div>
          </Grid>
        </Grid>
        {Boolean(errors.dsgvo) && (
          <p className={classes.agbsError}>{errors.dsgvo}</p>
        )}
        <Grid
          spacing={16}
          vertical
          root={{ top: 16, left: 0, right: 0, bottom: -16 }}
          justify="flex-end"
        >
          <Button
            item
            xs="120px"
            onClick={(e) => {
              e.preventDefault();
              props.onClose();
            }}
          >
            {state === "success" ? "Schließen" : "Abbrechen"}
          </Button>
          {state !== "success" && state !== "error" && (
            <Button
              item
              xs="120px"
              primary
              onClick={(e) => {
                e.preventDefault();
                const newErrors: { [key: string]: string } = {};
                if (!values.name) newErrors.name = "Ist ein Pflichtfeld.";
                if (!values.email) newErrors.email = "Ist ein Pflichtfeld.";
                if (!values.dsgvo)
                  newErrors.dsgvo = "Bitte akzeptieren Sie die DSGVO.";
                if (Object.keys(newErrors).length === 0)
                  sendContact(props.lot, values, setErrors, setState);
                else setErrors(newErrors);
              }}
            >
              Abschicken
            </Button>
          )}
        </Grid>
        <div />
        <div />
      </Form>
    </div>
  );
}
