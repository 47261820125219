import React, { useState } from "react";
import { Grid } from "@nodeme/grid-react";
import logo from "../assets/images/logo.jpg";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import acLogo from "../assets/images/artcare-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  children: React.ReactChild;
}

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  header: {
    height: "93px",
  },
  headerFixed: {
    zIndex: 900,
    width: "100%",
    position: "fixed",
    backgroundColor: "#000",
    padding: "12px 16px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.8)",
    boxSizing: "border-box",
  },
  logo: {
    width: "100%",
  },
  acLogo: {
    width: "100%",
    marginTop: "15px",
  },
  respMenuButton: {
    color: "#fff",
    fontSize: "2rem",
    textAlign: "right",
    padding: "10px 0px 0px 0px",
  },
  menuRespWrapper: {
    [theme.breakpoints.down("md")]: {
      display: (props: { open: boolean }) => (props.open ? "block" : "none"),
    },
  },
});

export default function Header(props: IProps) {
  const [open, setOpen] = useState(false);
  const classes = buildClasses(styles, { ...props, open });
  return (
    <header className={classes.header}>
      <div className={classes.headerFixed}>
        <Grid spacing={12}>
          <Grid item xs="80px" md="80px">
            <img className={classes.logo} src={logo} alt="yaa-logo" />
          </Grid>
          <Grid item xs="rest" md="hidden" lg="hidden" xl="hidden">
            <div className={classes.respMenuButton}>
              <FontAwesomeIcon
                icon={open ? faTimes : faBars}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={10} md="rest">
            <div className={classes.menuRespWrapper}>
              {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child))
                  return React.cloneElement(child, {
                    onClick: () => {
                      setOpen(false);
                    },
                  });
                return child;
              })}
            </div>
          </Grid>
          <Grid item xs="hidden" sm="hidden" md="120px">
            <img className={classes.acLogo} src={acLogo} alt="artcare-logo" />
          </Grid>
        </Grid>
      </div>
    </header>
  );
}
