import Color from "color";
export default (theme) => ({
    nodeMeForms: {
        formControl: {},
        label: {
            position: "absolute",
            fontSize: "0.8rem",
            color: "#797979",
            marginTop: "0px",
            zIndex: 0,
            transition: "margin-top 150ms linear, margin-left 150ms linear, font-size 150ms linear, color 150ms linear"
        },
        inlineLabel: {
            position: "absolute",
            fontSize: "1rem",
            marginTop: "5px",
            marginLeft: "8px",
            color: "#797979",
            cursor: "text",
            opacit: 1
        },
        closedLabel: {
            position: "absolute",
            fontSize: "1rem",
            marginTop: "23px",
            marginLeft: "8px",
            color: "#797979",
            cursor: "text"
        },
        inlineLabelOpen: {
            opacity: 0
        },
        inputHolder: {
            padding: "18px 0px 0px 0px"
        },
        input: {
            display: "block",
            width: "100%",
            height: "32px",
            boxSizing: "border-box",
            backgroundColor: theme.palette.get("formBackground"),
            outline: "none",
            border: "none",
            borderRadius: "2px",
            padding: "5px 8px  5px 8px",
            fontSize: "1rem",
            textOverflow: "ellipsis",
            "&:focus": {
                backgroundColor: theme.palette.get("formBackgroundActive"),
                transition: "background-color 150ms linear"
            }
        },
        inputReadOnly: {
            backgroundColor: "rgba(0, 0, 0, 0)",
            padding: "5px 8px  5px 2px",
            "&:focus": {
                backgroundColor: "rgba(0, 0, 0, 0)"
            }
        },
        textarea: {
            display: "block",
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: theme.palette.get("formBackground"),
            outline: "none",
            border: "none",
            borderRadius: "2px",
            padding: "5px 8px  5px 8px",
            fontSize: "1rem",
            textOverflow: "ellipsis",
            resize: "none",
            "&:focus": {
                backgroundColor: theme.palette.get("formBackgroundActive"),
                transition: "background-color 150ms linear"
            }
        },
        inputError: {},
        radioLabel: {
            display: "block",
            position: "relative",
            cursor: "pointer",
            userSelect: "none",
            padding: "5px 8px 5px 26px",
            boxSizing: "border-box"
        },
        radioPosition: {
            position: "absolute",
            margin: " 7px 0px 0px 0px",
            top: 0,
            left: 0,
            height: "20px",
            width: "20px"
        },
        radio: {
            opacity: 0,
            cursor: "pointer",
            "& span": {
                display: "block",
                margin: "3px",
                width: "0px",
                height: "0px",
                borderRadius: "10px",
                borderColor: theme.palette.get("formBackground")
            }
        },
        radioIcon: {
            borderRadius: "25px",
            border: `3px solid ${theme.palette.get("formBackground")}`,
            boxSizing: "border-box"
        },
        radioIconSelected: {
            borderColor: theme.palette.get("primary"),
            transition: "border-color 150ms linear",
            "& span": {
                display: "block",
                margin: "2px",
                width: "10px",
                height: "10px",
                borderRadius: "10px",
                backgroundColor: theme.palette.get("primary"),
                transition: "width 150ms linear, height 150ms linear, background-color 150ms linear,"
            }
        },
        dateInput: {
            width: "100%",
            padding: "0px",
            border: "none",
            margin: "none",
            outline: "none",
            fontSize: "1rem",
            backgroundColor: "rgba(224, 224, 224, 0)",
            transition: "opacity 150ms linear"
        },
        dateInputShow: {},
        button: {
            height: "32px",
            border: "none",
            outline: "none",
            background: theme.palette.get("default"),
            fontFamily: '"Noto Sans", sans-serif',
            fontSize: "1rem",
            cursor: "pointer",
            borderRadius: "2px",
            padding: "2px 8px",
            "&:hover": {
                background: "#bfbfbf"
            }
        },
        "button-primary": {
            background: theme.palette.get("primary"),
            color: "#fff",
            "&:hover": {
                background: Color(theme.palette.get("primary"))
                    .darken(0.3)
                    .hex()
            }
        },
        "button-warning": {
            background: theme.palette.get("warning"),
            color: "#fff",
            "&:hover": {
                background: Color(theme.palette.get("warning"))
                    .darken(0.3)
                    .hex()
            }
        },
        "button-success": {
            background: theme.palette.get("success"),
            color: "#fff",
            "&:hover": {
                background: Color(theme.palette.get("success"))
                    .darken(0.3)
                    .hex()
            }
        },
        "button-danger": {
            background: theme.palette.get("danger"),
            color: "#fff",
            "&:hover": {
                background: Color(theme.palette.get("danger"))
                    .darken(0.3)
                    .hex()
            }
        },
        helpBlock: {
            fontSize: "0.8rem",
            lineHeight: "0.8rem",
            color: "rgba(0, 0, 0, 0.75)"
        },
        hasError: {
            color: "rgba(156, 0, 0, 1)"
        }
    }
});
