import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const classStyle = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        button: {
            ...defaults.button,
            width: (props) => props.width || "100%"
        },
        "button-primary": defaults["button-primary"],
        "button-warning": defaults["button-warning"],
        "button-success": defaults["button-success"],
        "button-danger": defaults["button-danger"]
    };
};
const Button = props => {
    const { children, primary, success, warning, danger, type, onClick = () => { }, style = {} } = props;
    const variant = (primary && "primary") ||
        (success && "success") ||
        (warning && "warning") ||
        (danger && "danger") ||
        "default";
    const classes = buildClasses(classStyle, props);
    return (React.createElement("button", { className: classNames([classes.button, classes[`button-${variant}`]]), type: type, onClick: onClick, style: style }, children));
};
export default Button;
