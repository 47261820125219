import React from "react";
import ArtworkCard from "./ArtworkCard";
import ArtworkDetails from "./ArtworkDetails";
import Block from "./Shared/Block";
import { IProps as IAppProps } from "../App";

export interface IProps {
  id: number;
  category?: string;
  technique?: string;
  artist?: string;
  title?: string;
  artistDescription?: string;
  description?: string;
  width?: number;
  height?: number;
  depth?: number;
  price?: number;
  thumbExtension?: string;
  thumbHeight?: number;
  thumbId?: number;
  thumbName?: string;
  thumbUpdateDate?: string;
  thumbWidth?: number;
  active: boolean;
  year?: number;
  lot?: number;
  bid?: string;
  available?: number;
  mode: IAppProps["mode"];
  toggle: (lot?: number) => void;
}
export interface IState {
  thumb?: string | null;
  mockup?: string | null;
  open: boolean;
  debug?: string;
}

export default class Artwork extends React.Component<IProps, IState> {
  MOUNT = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
    };
    this.loadImage = this.loadImage.bind(this);
    this.loadDetails = this.loadDetails.bind(this);
    this.setImage = this.setImage.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.loadImage("thumb", {
      id: this.props.thumbId,
      updateDate: this.props.thumbUpdateDate,
      extension: this.props.thumbExtension,
      name: this.props.thumbName,
    });
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  setImage(type: "thumb" | "mockup", base64: string) {
    if (this.MOUNT) this.setState({ ...this.state, [type]: base64 });
  }
  async loadImage(
    type: "thumb" | "mockup",
    data: {
      id?: number;
      updateDate?: string;
      extension?: string;
      name?: string;
    }
  ) {
    if (
      typeof this.state[type] === "undefined" &&
      data.id &&
      data.extension &&
      data.name &&
      data.updateDate
    ) {
      const reader = new window.FileReader();
      const { setImage } = this;
      reader.onload = function () {
        setImage(type, this.result + "");
      };
      reader.onerror = () => {
        if (this.MOUNT) this.setState({ ...this.state, [type]: null });
      };
      await fetch(
        `https://artware2.datenbanker.at/api/picture/public/${data.id}/lg/0/${data.name}.${data.extension}`
      ).then(async (response) => reader.readAsDataURL(await response.blob()));
    } else if (typeof this.state[type] !== "string") {
      this.setState({ ...this.state, [type]: null });
    }
  }
  async loadDetails() {
    try {
      //load details
      const received = await fetch(
        `https://artware2.datenbanker.at/api/artwork/${this.props.id}/public`
      ).then((response) => response.json());
      //load mock
      const { mockup } = received.artwork.data;
      if (mockup.hasData)
        this.loadImage("mockup", {
          id: mockup.data.id,
          name: mockup.data.name,
          extension: mockup.data.extension,
          updateDate: mockup.data.updateDate,
        });
      else if (this.MOUNT) this.setState({ ...this.state, mockup: null });
    } catch (error) {
      if (this.MOUNT) this.setState({ ...this.state, mockup: null });
    }
  }
  render() {
    if (this.state.debug)
      return (
        <Block>
          <span style={{ color: "red" }}>{this.state.debug}</span>
        </Block>
      );
    if (this.props.active)
      return (
        <ArtworkDetails
          mode={this.props.mode}
          id={this.props.id}
          thumb={this.state.thumb}
          mockup={this.state.mockup}
          lot={this.props.lot}
          artist={this.props.artist}
          title={this.props.title}
          category={this.props.category}
          technique={this.props.technique}
          width={this.props.width}
          height={this.props.height}
          depth={this.props.depth}
          description={this.props.description}
          artistDescription={this.props.artistDescription}
          year={this.props.year}
          price={this.props.price}
          bid={this.props.bid}
          available={this.props.available}
          onClose={() => {
            this.props.toggle(this.props.lot);
          }}
        />
      );
    return (
      <ArtworkCard
        mode={this.props.mode}
        available={this.props.available}
        id={this.props.id}
        thumb={this.state.thumb}
        lot={this.props.lot}
        artist={this.props.artist}
        title={this.props.title}
        bid={this.props.bid}
        onClick={() => {
          this.props.toggle(this.props.lot);
          this.loadDetails();
        }}
      />
    );
  }
}
