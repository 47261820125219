import React from "react";
import { Grid } from "@nodeme/grid-react";
import PendingBlock from "./Shared/PendingBlock";
import Artwork, { IProps as IArtwork } from "./BidCard";
import ErrorBlock from "./Shared/ErrorBlock";
import Block from "./Shared/Block";

export interface IProps {}
export interface IState {
  data: IArtwork[];
  pending: boolean;
  unknownError: boolean;
  evaluation?: string;
}

export default class Artworks extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      pending: false,
      unknownError: false,
    };
    this.load = this.load.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/public/vbkoe"
      ).then((response) => response.json());
      let data: IState["data"] = received
        .sort((a: any, b: any) => {
          const aNr = parseInt((a.data.description || "").split(" #")[0]) || 0;
          const bNr = parseInt((b.data.description || "").split(" #")[0]) || 0;
          return aNr - bNr;
        })
        .map((item: any) => {
          return {
            id: item.data.id,
            artist: item.data.name,
            title: item.data.title,
            bid: item.data.rentalPrice,
            description: (item.data.description || "").split(" #")[1] || "",
            lot: parseInt((item.data.description || "").split(" #")[0]) || 0,
            price: item.data.sellingPrice,
          };
        });
      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }

  render() {
    if (this.state.unknownError)
      return <ErrorBlock text="Werke werden geladen..." />;
    if (this.state.pending)
      return <PendingBlock text="Werke werden geladen..." />;
    return (
      <Grid spacing={0} vertical>
        <Grid item>
          <Block>{(this.state.data[0] || {}).description || ""}</Block>
        </Grid>
        {this.state.data.slice(1).map((artwork: IArtwork, index: number) => (
          <Grid key={"artwork-" + artwork.id} item lg={5}>
            <Artwork
              id={artwork.id}
              lot={artwork.lot}
              artist={artwork.artist}
              title={artwork.title}
              bid={artwork.bid}
              price={artwork.price}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}
