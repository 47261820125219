import React from "react";
import { Grid } from "@nodeme/grid-react";
import PendingBlock from "./Shared/PendingBlock";
import ErrorBlock from "./Shared/ErrorBlock";
import Host, { IProps as IHost } from "./Host";

export interface IProps {}
export interface IState {
  data: IHost[];
  pending: boolean;
  unknownError: boolean;
}

export default class Artworks extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      pending: false,
      unknownError: false,
    };
    this.load = this.load.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.load();
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async load() {
    this.setState({ ...this.state, pending: true });
    try {
      const received = await fetch(
        "https://artware2.datenbanker.at/api/artwork/public/yaahosts"
      ).then((response) => response.json());
      console.log(
        received.sort((item: any) => {
          return item.data.id === 7714 ? 1 : 0;
        })
      );

      const data: IState["data"] = received
        .sort((item: any) => {
          return item.data.id === 7714 ? 1 : 0;
        })
        .map((item: any) => {
          return {
            id: item.data.id,
            name: item.data.title,
            description: item.data.description,
            thumbExtension: item.data.originalThumbExtension,
            thumbHeight: item.data.originalThumbHeight,
            thumbId: item.data.originalThumbId,
            thumbName: item.data.originalThumbName,
            thumbUpdateDate: item.data.originalThumbUpdateDate,
            thumbWidth: item.data.originalThumbWidth,
          };
        });
      if (this.MOUNT) this.setState({ ...this.state, data, pending: false });
    } catch (error) {
      if (this.MOUNT)
        this.setState({ ...this.state, pending: false, unknownError: true });
    }
  }

  render() {
    if (this.state.unknownError)
      return <ErrorBlock text="Werke werden geladen..." />;
    if (this.state.pending)
      return <PendingBlock text="Werke werden geladen..." />;

    const col1 = this.state.data.filter(
      (host: IHost, index: number) => index % 3 === 0
    );
    const col2 = this.state.data.filter(
      (host: IHost, index: number) => index % 3 === 1
    );
    const col3 = this.state.data.filter(
      (host: IHost, index: number) => index % 3 === 2
    );
    return (
      <Grid spacing={16} vertical>
        <Grid item lg={10 / 3}>
          {col1.map((host: IHost) => (
            <Host
              key={"host-" + host.id}
              id={host.id}
              name={host.name}
              description={host.description}
              thumbExtension={host.thumbExtension}
              thumbHeight={host.thumbHeight}
              thumbWidth={host.thumbWidth}
              thumbId={host.thumbId}
              thumbName={host.thumbName}
              thumbUpdateDate={host.thumbUpdateDate}
            />
          ))}
        </Grid>
        <Grid item lg={10 / 3}>
          {col2.map((host: IHost) => (
            <Host
              key={"host-" + host.id}
              id={host.id}
              name={host.name}
              description={host.description}
              thumbExtension={host.thumbExtension}
              thumbHeight={host.thumbHeight}
              thumbWidth={host.thumbWidth}
              thumbId={host.thumbId}
              thumbName={host.thumbName}
              thumbUpdateDate={host.thumbUpdateDate}
            />
          ))}
        </Grid>
        <Grid item lg={10 / 3}>
          {col3.map((host: IHost) => (
            <Host
              key={"host-" + host.id}
              id={host.id}
              name={host.name}
              description={host.description}
              thumbExtension={host.thumbExtension}
              thumbHeight={host.thumbHeight}
              thumbWidth={host.thumbWidth}
              thumbId={host.thumbId}
              thumbName={host.thumbName}
              thumbUpdateDate={host.thumbUpdateDate}
            />
          ))}
        </Grid>
      </Grid>
    );
  }
}
