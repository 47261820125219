import React from "react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Block from "./Block";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";

export interface IProps {
  text?: string;
}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  pendingIcon: {
    fontSize: "5rem",
    color: theme.palette.get("danger"),
  },
  pendingBlock: {
    textAlign: "center",
    padding: "36px 0px",
  },
  pendingText: {
    color: theme.palette.get("grey"),
    fontWeight: 100,
  },
});

export default function ErrorBlock(props: IProps) {
  const classes = buildClasses(style, props);
  return (
    <Grid spacing={12} vertical justify="center">
      <Grid item md="300px">
        <Block>
          <div className={classes.pendingBlock}>
            <FontAwesomeIcon
              className={classes.pendingIcon}
              icon={faExclamationTriangle}
            />
            <p style={{ marginTop: 24 }}>
              Ein unbekannter Fehler ist aufgetreten.
            </p>
            <p className={classes.pendingText}>
              Bitte versuchen Sie es später erneut.
            </p>
          </div>
        </Block>
      </Grid>
    </Grid>
  );
}
