import React from "react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";

export interface IProps {
  children: React.ReactNode;
}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  block: {
    backgroundColor: "#fff",
    padding: "16px",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 4px 0px",
    boxSizing: "border-box",
  },
});

export default function Block(props: IProps) {
  const classes = buildClasses(style, props);
  return <div className={classes.block}>{props.children}</div>;
}
