import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import { isRow } from "../Interfaces/IRow";
import classnames from "classnames";
const getColSize = (from, props) => {
    const index = ["xl", "lg", "md", "sm", "xs"].indexOf(from);
    return index === 0 &&
        (typeof props.xl === "number" || typeof props.xl === "string")
        ? props.xl
        : index <= 1 &&
            (typeof props.lg === "number" || typeof props.lg === "string")
            ? props.lg
            : index <= 2 &&
                (typeof props.md === "number" || typeof props.md === "string")
                ? props.md
                : index <= 3 &&
                    (typeof props.sm === "number" || typeof props.sm === "string")
                    ? props.sm
                    : index <= 4 &&
                        (typeof props.xs === "number" || typeof props.xs === "string")
                        ? props.xs
                        : typeof props.parts === "number"
                            ? props.parts
                            : 10;
};
const getColFlow = (from, props) => {
    const { parts = 10 } = props;
    const size = getColSize(from, props);
    if (typeof size === "string")
        return size === "rest-fixed"
            ? "1 0 0"
            : size === "rest"
                ? "1 0 auto"
                : `0 0 ${size}`;
    if (typeof size === "number")
        return `0 0 ${(size / parts) * 100}%`;
    else
        return "0 0 100%";
};
const getDisplay = (from, props) => {
    if (from === "xl")
        return props.xl === "hidden" || props.xl === "hidden-down"
            ? "none"
            : "block";
    if (from === "lg")
        return props.lg === "hidden" ||
            props.xl === "hidden-down" ||
            props.lg === "hidden-down"
            ? "none"
            : "block";
    if (from === "md")
        return props.md === "hidden" ||
            props.xl === "hidden-down" ||
            props.lg === "hidden-down" ||
            props.md === "hidden-down"
            ? "none"
            : "block";
    if (from === "sm")
        return props.sm === "hidden" ||
            props.xl === "hidden-down" ||
            props.lg === "hidden-down" ||
            props.md === "hidden-down" ||
            props.sm === "hidden-down"
            ? "none"
            : "block";
    return props.xs === "hidden" ||
        props.xl === "hidden-down" ||
        props.lg === "hidden-down" ||
        props.md === "hidden-down" ||
        props.sm === "hidden-down" ||
        props.xs === "hidden-down"
        ? "none"
        : "block";
};
const rowStyles = (theme) => (definitions) => ({
    root: {
        padding: (props) => {
            const defaultHSize = props.spacing || 0;
            const defaultVSize = props.vertical ? props.spacing || 0 : 0;
            const root = props.root || {};
            return `${typeof root.top !== "undefined" ? root.top : defaultVSize}px ${typeof root.right !== "undefined" ? root.right : defaultHSize}px ${typeof root.bottom !== "undefined" ? root.bottom : defaultVSize}px ${typeof root.left !== "undefined" ? root.left : defaultHSize}px`;
        },
    },
    row: {
        margin: (props) => `${props.vertical ? (props.spacing || 0) / -2 : 0}px -${(props.spacing || 0) / 2}px`,
        display: "flex",
        justifyContent: (props) => props.justify || "flex-start",
        alignItems: (props) => props.alignItems || "flex-start",
        flexFlow: (props) => props.flow || "wrap row",
    },
});
const Row = (props) => {
    const { spacing, parts = 10, vertical = false, children, override = {}, } = props;
    const classes = buildClasses(rowStyles, props);
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classnames([classes.row, override.row]) }, React.Children.map(children, (child) => {
            if (child === null)
                return null;
            const { xs, sm, md, lg, xl } = child.props;
            if (child.type.name === "Grid" && child.props.item) {
                return (React.createElement(Col, Object.assign({ item: true }, { xs, sm, md, lg, xl, spacing, parts, vertical }), child.props.children));
            }
            else {
                return (React.createElement(Col, Object.assign({ item: true }, { xs, sm, md, lg, xl, spacing, parts, vertical }), child));
            }
        }))));
};
const colStyles = (theme) => (definitions) => ({
    col: {
        padding: (props) => `${props.vertical ? (props.spacing || 0) / 2 : 0}px ${(props.spacing || 0) / 2}px`,
        maxWidth: "100%",
        boxSizing: "border-box",
        display: (props) => getDisplay("xs", props),
        flex: (props) => getColFlow("xs", props),
        [theme.breakpoints.up("sm")]: {
            display: (props) => getDisplay("sm", props),
            flex: (props) => getColFlow("sm", props),
        },
        [theme.breakpoints.up("md")]: {
            display: (props) => getDisplay("md", props),
            flex: (props) => getColFlow("md", props),
        },
        [theme.breakpoints.up("lg")]: {
            display: (props) => getDisplay("lg", props),
            flex: (props) => getColFlow("lg", props),
        },
        [theme.breakpoints.up("xl")]: {
            display: (props) => getDisplay("xl", props),
            flex: (props) => getColFlow("xl", props),
        },
    },
});
const Col = React.memo(({ spacing, parts = 10, xs, sm, md, lg, xl, children, vertical }) => {
    const classes = buildClasses(colStyles, {
        spacing,
        parts,
        xs,
        sm,
        md,
        lg,
        xl,
        vertical,
    });
    return React.createElement("div", { className: classes.col }, children);
});
const Grid = (props) => {
    if (isRow(props))
        return Row(props);
    return props.children;
};
export default Grid;
export { Row, Col };
