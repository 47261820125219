import { Container } from "@nodeme/grid-react";
import React from "react";
import "./assets/styles/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Artworks from "./components/Artworks";
import Hosts from "./components/Hosts";
import Sponsors from "./components/Sponsors";
import Impressum from "./components/Impressum";
import Bids from "./components/Bids";

export interface IProps {
  mode: "SELLING" | "SHOWING";
}

function App(props: IProps) {
  return (
    <div className="App">
      <Router>
        <Header>
          <Navigation />
        </Header>
        <main>
          <div style={{ backgroundColor: "#000", height: "150px" }} />
          <div style={{ marginTop: "-124px" }}>
            <Container notFluid>
              <Switch>
                <Route path="/" exact>
                  <Artworks mode={props.mode} />
                </Route>
                <Route path="/bids" exact>
                  <Bids />
                </Route>
                <Route path="/hosts" exact>
                  <Hosts />
                </Route>
                <Route path="/sponsors" exact>
                  <Sponsors />
                </Route>
                <Route path="/impressum" exact>
                  <Impressum />
                </Route>
              </Switch>
            </Container>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default App;
