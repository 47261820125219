import React from "react";
import { Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import Block from "./Shared/Block";

export interface IProps {}

const styles = (theme: ITheme) => (definition: IDefinitions) => ({
  subheading: {
    letterSpacing: "0.1rem",
    color: theme.palette.get("primary"),
    fontSize: "0.9rem",
    fontWeight: "700",
    textTransform: "uppercase",
    margin: 0,
  },
  text: {},
  lable: {
    color: theme.palette.get("grey"),
    marginRight: "4px",
  },
});

export default function Header(props: IProps) {
  const classes = buildClasses(styles, props);
  return (
    <Grid spacing={12} vertical>
      <Grid item xs={10} lg={5}>
        <Block>
          <h6 className={classes.subheading}>
            FUNDRAISING AUCTION for the VBKÖ – Verein Bildender Künstlerinnen
            Österreich
          </h6>
          <p>Dear friends of the VBKÖ!</p>
          <p>
            The{" "}
            <a href="https://www.vbkoe.org/?lang=en">
              VBKÖ - Association of Women Artists in Austria
            </a>{" "}
            is excited to invite you to its annual Fundraising Auction, closing
            its exhibition year under the topic “Agency of Failure.” In 2020, it
            can be said that failing and failure have garnered new depth of
            meaning and regarding it as a new start requires a lot of personal
            effort these days. Nonetheless, we want to close the year with an
            act of solidarity and enthusiasm for the Arts, which needs your
            support now more than ever.
          </p>
          <p>
            We would like to invite you to take a look at this year’s online
            auction, featuring works by 14 VBKÖ member artists in a diverse
            range of mediums. Half of your contribution will go directly towards
            the VBKÖ, which will ensure the upkeep of the historical exhibition
            space and queer-feminist programming, and the other half will go to
            the respective artist (fifty/fifty)*
          </p>
          <p>
            We thank you for your contribution to the VBKÖ and hope that you
            will find great joy in your new artwork.
          </p>
          <p>
            Yours, <br />
            Board members of the VBKÖ
          </p>
          <p style={{ fontSize: "0.7rem", fontStyle: "italic" }}>
            *Please also note that pick-up and eventual transport costs are the
            responsibility of the buyers themselves. If you have questions
            regarding the works or artists please contact opencall@vbkoe.org.
          </p>
        </Block>
      </Grid>
      <Grid item xs={10} lg={5}>
        <Block>
          <h6 className={classes.subheading}>Contributing artists</h6>
          <p>
            Veronika Burger, Louise Deininger, Veronika Dirnhofer, Hilde Fuchs,
            Fanni Futterknecht, Vinko Nino Jäger, Ma Jia, Berenice Pahl, Anna
            Reisenbichler, Claudia Sandoval Romero, Käthe Schönle, Alexandra
            Tatar, Christina Werner, Khadija von Zinnenburg Carroll
          </p>
        </Block>
        <div style={{ marginTop: "16px" }} />
        <Block>
          <h6 className={classes.subheading}>Kontakt</h6>
          <p>
            ARTCARE Marketing und Vertriebs GmbH
            <br />
            Rechte Bahngasse 30-32, 1030 Wien
            <br />
            <span className={classes.lable}>Tel.:</span> +43/681 105 42 464
            <br />
            <span className={classes.lable}>E-Mail:</span> office@artcare.at
            <br />
            <span className={classes.lable}>Firmenbuchnummer:</span> FN 287030d
            Og HG Wien
            <br />
            <span className={classes.lable}>UID Nummer:</span> ATU 74758924
          </p>
        </Block>
      </Grid>
    </Grid>
  );
}
