import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Block from "./Shared/Block";

export interface IProps {
  id: number;
  name?: string;
  description?: string;
  thumbExtension?: string;
  thumbHeight?: number;
  thumbId?: number;
  thumbName?: string;
  thumbUpdateDate?: string;
  thumbWidth?: number;
}
export interface IState {
  thumb?: string | null;
}

export default class Artworks extends React.Component<IProps, IState> {
  MOUNT = false;
  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.loadImage = this.loadImage.bind(this);
  }
  componentDidMount() {
    this.MOUNT = true;
    this.loadImage("thumb", {
      id: this.props.thumbId,
      updateDate: this.props.thumbUpdateDate,
      extension: this.props.thumbExtension,
      name: this.props.thumbName,
    });
  }
  componentWillUnmount() {
    this.MOUNT = false;
  }
  async loadImage(
    type: "thumb",
    data: {
      id?: number;
      updateDate?: string;
      extension?: string;
      name?: string;
    }
  ) {
    if (
      typeof this.state[type] === "undefined" &&
      data.id &&
      data.extension &&
      data.name &&
      data.updateDate
    ) {
      const img = new Image();
      const src = `https://artware2.datenbanker.at/api/picture/public/${
        data.id
      }/lg/0/${
        data.name
      }.${data.extension}`;
      img.onload = () => {
        if (this.MOUNT) this.setState({ ...this.state, [type]: src });
      };
      img.onerror = () => {
        if (this.MOUNT) this.setState({ ...this.state, [type]: null });
      };
      img.src = src;
    } else if (typeof this.state[type] !== "string") {
      this.setState({ ...this.state, [type]: null });
    }
  }
  render() {
    return (
      <div style={{ marginBottom: "16px" }}>
        <Block>
          {!this.state.thumb && (
            <div
              style={{
                height: "280px",
                width: "calc(100% + 32px)",
                margin: "-16px -16px 16px -16px",
                boxSizing: "border-box",
                backgroundColor: "#ececec",
                backgroundRepeat: "no-repeat",
                color: "#246cb5",
                fontSize: "4rem",
                textAlign: "center",
                paddingTop: "95px",
              }}
            >
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </div>
          )}
          {Boolean(this.state.thumb) && (
            <div
              style={{
                height: "280px",
                width: "calc(100% + 32px)",
                margin: "-16px -16px 16px -16px",
                backgroundColor: "#ececec",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url("${this.state.thumb}")`,
                backgroundPositionX: "center"
              }}
            />
          )}
          {this.props.name}
          <div
            style={{ fontSize: "0.8rem", fontWeight: 100 }}
            dangerouslySetInnerHTML={{ __html: this.props.description || "" }}
          ></div>
        </Block>
      </div>
    );
  }
}
