import React, { useState, createRef } from "react";
import { buildClasses } from "@nodeme/jss-react";
import classNames from "classnames";
const style = (theme) => (definitions) => {
    const defaults = definitions.nodeMeForms;
    return {
        formControl: defaults.formControl,
        label: defaults.label,
        inlineLabel: defaults.inlineLabel,
        closedLabel: defaults.closedLabel,
        inlineLabelOpen: defaults.inlineLabelOpen,
        inputReadOnly: defaults.inputReadOnly,
        inputHolder: defaults.inputHolder,
        input: defaults.input,
        textarea: defaults.textarea,
        inputError: defaults.inputError,
        helpBlock: defaults.helpBlock,
        hasError: defaults.hasError
    };
};
const Numbers = props => {
    const { label, open = false, inline = false, readOnly = false, value, onChange, error, autoComplete = "off", override = {} } = props;
    const [isFocused, setFocuse] = useState(false);
    const classes = buildClasses(style);
    const inputRef = createRef();
    const hasError = Boolean(error);
    return (React.createElement("div", { className: classes.formControl },
        React.createElement("label", { htmlFor: label, className: classNames([
                inline ? classes.inlineLabel : classes.label,
                !inline &&
                    !open &&
                    !isFocused &&
                    !Boolean(value) &&
                    classes.closedLabel,
                inline && Boolean(value) && classes.inlineLabelOpen,
                hasError && classes.hasError
            ]), onClick: e => {
                if (!isFocused && inputRef && inputRef.current) {
                    inputRef.current.focus();
                }
            } }, label),
        React.createElement("div", { className: classNames([!inline && classes.inputHolder]) },
            React.createElement("input", { className: classNames([
                    classes.input,
                    hasError && classes.inputError,
                    override.input
                ]), name: label, ref: inputRef, type: "text", value: value || "", autoComplete: autoComplete, onChange: e => {
                    const value = e.target.value;
                    if (readOnly || !onChange)
                        return;
                    else if (value === "")
                        onChange();
                    else if (typeof value === "string" && !Number.isNaN(Number(value)))
                        onChange(Number(value));
                    else if (typeof value === "number" && !Number.isNaN(value))
                        onChange(value);
                    else
                        onChange();
                }, onFocus: e => setFocuse(true), onBlur: e => setFocuse(false) }),
            hasError && (React.createElement("span", { className: classNames([classes.helpBlock, classes.hasError]) }, error)))));
};
export default Numbers;
