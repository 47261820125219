import React from "react";
import { Grid } from "@nodeme/grid-react";
import Block from "./Shared/Block";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import classNames from "classnames";

export interface IProps {
  id: number;
  artist?: string;
  title?: string;
  thumb?: string | null;
  lot?: number;
  bid?: string;
  description?: string;
  price?: number;
}
export interface IState {}

const style = (theme: ITheme) => (definition: IDefinitions) => ({
  lot: {
    color: theme.palette.get("primary"),
    padding: "0px 8px 0px 0px",
    fontSize: "3rem",
    lineHeight: "2rem",
    fontWeight: 600,
  },
  lineHolder: {
    position: "relative",
  },
  line: {
    position: "absolute",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    textOverflow: "ellipsis",
  },
  artist: {
    top: "-5px",
  },
  title: {
    top: "22px",
    color: theme.palette.get("grey"),
    fontSize: "0.8rem",
    fontWeight: 100,
  },
  thumb: {
    height: "220px",
    width: "calc(100% + 32px)",
    margin: "-16px -16px 16px -16px",
    backgroundColor: theme.palette.get("lightGrey"),
    color: theme.palette.get("primary"),
    textAlign: "center",
    fontSize: "4rem",
    paddingTop: "64px",
    boxSizing: "border-box",
    display: "block",
    backgroundPosition: "center",
    backgroundImage: (props: IProps) =>
      props.thumb ? `url("${props.thumb}")` : "none",
  },
  card: {
    cursor: "pointer",
  },
  primary: {
    color: theme.palette.get("primary"),
    fontWeight: 400,
  },
});

export default function ArtworkCard(props: IProps) {
  const classes = buildClasses(style, { ...props });

  return (
    <div className={classes.card}>
      <Block>
        <Grid>
          <Grid item xs="auto">
            <span className={classes.lot}>{props.lot || props.id}</span>
          </Grid>
          <Grid item xs={8}>
            <Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  <span className={classNames([classes.line, classes.artist])}>
                    {props.artist}
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.lineHolder}>
                  {props.bid === "0.00" && (
                    <span className={classNames([classes.line, classes.title])}>
                      Noch kein Gebot.
                    </span>
                  )}
                  {props.bid !== "0.00" && (
                    <span className={classNames([classes.line, classes.title])}>
                      Aktuelles Gebot:{" "}
                      <span className={classes.primary}>{props.bid} €</span> |{" "}
                      Rufpreis: {(props.price || 0) / 2} €
                    </span>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Block>
    </div>
  );
}
